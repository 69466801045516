<template>
  <div>
    <div v-if="!cookie" class="cookie-consent">
      <p class="cookie-consent__text" v-html="$prismic.asHtml(this.cookies_text)"></p>
      <button class="main-button" @click="acceptCookie">{{ $prismic.richTextAsPlain(this.cookies_button_text) }}</button>
      <button @click.prevent="hideCookie" class="cookie-consent__button-close">
        <b-icon icon="x" font-scale="2"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiesComponent',
  data: () => {
    return {
      cookie: true,
      cookies_text: null,
      cookies_button_text: null
    }
  },
  methods: {
    acceptCookie () {
      this.$cookie.set('chimni_cookie', 'true', 360)
      this.cookie = true
    },
    hideCookie () {
      this.cookie = true
    },
    async getCookiesContent () {
      await this.$prismic.client.getSingle('cookies')
        .then((document) => {
          // cookies section
          this.cookies_text = document.data.cookies_text
          this.cookies_button_text = document.data.cookies_button_text
        })
    }
  },
  created () {
    if (this.$cookie.get('chimni_cookie') === null) {
      this.getCookiesContent()
      this.cookie = false
    }
  }
}
</script>

<style lang="scss">
  @import "./CookiesComponent.scss";
</style>
